<template>
  <div class="textContainer">
    <div class="text">
      <h5>Våre samarbeidspartnere, sponsorer og støttespillere.</h5>
    </div>
  </div>
  <!-- Main container -->
  <div class="container">
    <!-- Splide slider -->
    <div id="image-slider" class="splide">
      <div class="splide__track">
        <Splide :options="options">
          <SplideSlide>
            <a href="http://geoloco.com/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/geoloco.jpg"
                alt="Geoloco"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="https://vestfoldgeologi.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/steingarne.jpg"
                alt="Vestfold geologi"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="http://norskufosenter.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/ufologo.jpg"
                alt="Norsk UFO senter"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="http://sparebankstiftelsen.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/sparebank-1.jpg"
                alt="Sparebankstiftelsen"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="https://www.treasuresfromspace.com/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/treasures.jpg"
                alt="Treasure from space"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="http://nhm.uio.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/nmh-logo-liten.jpg"
                alt="Naturhistorisk museum"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="http://solobservatoriet.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/solobservatoriet.jpg"
                alt="Solobservatoriet"
              />
            </a>
          </SplideSlide>
          <SplideSlide>
            <a href="https://www.astronomi.no/" target="_blank">
              <img
                src="https://web01.usn.no/~240980/app2000/img/NAS.jpg"
                alt="Norsk astronomisk selskap"
              />
            </a>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import { defineComponent } from 'vue'
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css'

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },

  setup() {
    const options = {
      padding: '1rem',
      rewind: true,
      speed: 500,
      type: 'fade',
    }

    return { options }
  },
})
</script>

<style scoped>
/* Text container */
.textContainer {
  height: 100px;
  position: relative;
  margin-top: 3%;
  margin-bottom: -3%;
}
.text {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
h5 {
  text-align: center;
  font-size: x-large;
}
/* Main container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Style img */
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3px;
  width: auto;
  height: auto;
  border-radius: 20px;
  -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
/* Responsive img for mobile phones*/
@media only screen and (max-width: 767px) {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 200px;
    border-radius: 20px;
  }
  .text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  h5 {
    text-align: center;
  }
}
</style>
